<template>
    <div v-loading="$wait.is('service.categories.loading')" class="w-full card" element-loading-spinner="el-custom-spinner">
        <ConfirmationModal @success="getAll" />

        <ModalAddServiceCategory
            :modalAddServiceCategory="modalAddServiceCategory"
            :employeeUuid="employeeUuid"
            :servicesCategoryData="servicesCategoryData"
            @refresh="getAll"
            @close="modalAddServiceCategory = false"
        />

        <div class="flex items-center justify-between border-b border-gray-300 pb-4 mb-5">
            <h1>{{ $t('employee_details.assigned_service_categories') }}</h1>
            <el-button type="primary" @click="showModalAddServiceCategory">
                {{ $t('common.add') }}
            </el-button>
        </div>

        <ServiceCategoriesTable
            :servicesCategoryData="servicesCategoryData"
            @handleDelete="handleDelete"
        />

        <NoDataInformation :data="noDataInformation" waitKey="service.categories.loading" />
    </div>
</template>
<script>
export default {
    components: {
        ModalAddServiceCategory: () => import(/* webpackChunkName: "EmployeeDamages/ModalAddDamage" */ './modals/ModalAddServiceCategory'),
        ServiceCategoriesTable:  () => import(/* webpackChunkName: "EmployeeDamages/DamagesTable" */ './components/ServiceCategoriesTable'),
    },

    data() {
        return {
            noDataInformation:       false,
            employeeUuid:            this.$route.params.uuid,
            selectedDamage:          {},
            servicesCategoryData:    [],
            modalAddServiceCategory: false,
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'assigned_service_categories');
    },

    async created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('service.categories.loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/assigned_categories`);
            this.servicesCategoryData = data;
            this.noDataInformation = data.length;
            this.$wait.end('service.categories.loading');
        },

        showModalAddServiceCategory() {
            this.modalAddServiceCategory = true;
        },

        async handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl: `${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/assigned_categories/${uuid}`,
            });
        },
    },
};
</script>
